<template>
  <div class="resource-body">
    <TheNav />

    <div class="content">
      <div class="slogan">
        <img
          src="https://wechatapppro-1252524126.file.myqcloud.com/app45DDvGw38268/image/b_u_5b7457ba21ff8_i0DEvgEY/l4519mgr0549.png"
          alt=""
        />
      </div>
      <div class="tabs">
        <button
          v-for="(item, index) in category"
          :key="item.id"
          @click="changeList(item.name, index)"
          :class="{ active: cla == index }"
        >
          {{ item.name }}
        </button>
      </div>
      <div class="cards">
        <a
          :href="item.link"
          target="_blank"
          class="card"
          v-for="item in categoryResult"
          :key="item.id"
        >
          <img :src="item.logo" class="logo" />{{ item.name }}
          <p class="description">{{ item.description }}</p>
        </a>
      </div>
      <p class="height"></p>
    </div>
  </div>

  <TheFooter />
</template>

<script>
import resource from "../assets/resource.json";
import { onMounted, ref, computed } from "vue";
import TheFooter from "../views/TheFooter.vue";
import TheNav from "../views/TheNav.vue";
export default {
  components: {TheNav},
  setup() {
    components: {
      TheFooter, TheNav;
    }
    const category = [
      { name: "全部资源", num: 0 },
      { name: "照片", num: 1 },
      { name: "视频", num: 9 },
      { name: "音频", num: 5 },
      { name: "插画", num: 2 },
      { name: "图标", num: 8 },
      { name: "工具", num: 3 },
      { name: "纹理", num: 6 },
      { name: "背景图片", num: 7 },
      { name: "Mockup", num: 4 },
      { name: "色彩工具", num: 10 },
      { name: "设计灵感", num: 11 },
      { name: "线框图", num: 12 },
    ];
    const cla = ref("0");
    const categoryResult = ref(resource);

    function changeList(name, index) {
      cla.value = index;
      if (name == "全部资源") {
        categoryResult.value = resource;
      } else {
        categoryResult.value = resource.filter((item) => {
          console.log(item.category[0], name);
          return (
            item.category[0] == name ||
            item.category[1] == name ||
            item.category[2] == name ||
            item.category[3] == name ||
            item.category[4] == name
          );
        });
      }
    }
    return {
      TheFooter,
      TheNav,
      resource,
      category,
      changeList,
      cla,
      categoryResult,
    };
  },
};
</script>

<style scoped>
.resource-body {
  width: 100%;
  min-height: 800px;
  background: #f5f6f9f8;
}
.content {
  width: 1240px;
  margin: 0 auto;
}

.tabs {
  width: 100%;
  margin-bottom: 10px;
}

.tabs button {
  margin: 0 8px;
  color: rgb(112, 112, 112);
  cursor: pointer;
  padding: 7px 12px;
  border-radius: 5px;
  border: none;
  margin-right: 10px;
  margin-bottom: 15px;
  background: #fff;
}

.tabs button:hover {
  color: #fff;
  background: #a2c217;
}
.content .tabs .active {
  color: #fff;
  background: #a2c217;
}

.card {
  display: inline-block;
  width: 294px;
  height: 105px;
  background: #fff;
  margin: 15px 8px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.card:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.07);
  transition: 0.3s;
}

.card .logo {
  width: 15%;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 15px;
}

.card .description {
  font-size: 13px;
  margin-top: 10px;
  margin-left: 2px;
  color: rgb(117, 117, 117);
}

.slogan {
  width: 100%;
  height: 170px;
  padding-top: 30px;
  text-align: center;
}
.slogan img {
  width: 50%;
}

.height {
  height: 120px;
}

@media screen and (max-width: 470px) {
  .content {
    width: 95%;
  }

  .card {
    width: 100%;
    height: 95px;
    margin: 10px 0px;
    padding: 15px;
  }
  .card .logo {
    width: 10%;
  }
  .slogan {
    width: 100%;
    height: 100px;
    padding-top: 10px;
    text-align: center;
  }
  .slogan img {
    width: 95%;
  }

  .tabs button {
    font-size: 0.7em;
  }
}

@media screen and (min-width: 471px) and (max-width: 1024px) {
  .content {
    width: 95%;
  }

  .card {
    width: 45%;
    height: 95px;
    margin: 10px 17px;
    padding: 15px;
  }
  .card .logo {
    width: 10%;
  }
  .slogan {
    width: 90%;
    height: 130px;
    padding-top: 10px;
    text-align: center;
  }
  .slogan img {
    width: 95%;
  }

  .tabs button {
    font-size: 0.8em;
  }
}
</style>